import React, { useState } from 'react'
import { useQuery } from 'react-query'
import cx from 'classnames'

import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { fetchActionLogs } from '../queries/queries'
import { CloseIcon, FullScreenIcon } from '@toasttab/buffet-pui-icons'
import { Checkbox } from '@toasttab/buffet-pui-checkbox'

const Convert = require('ansi-to-html')
const ansi = new Convert({
  escapeXML: true,
  stream: true,
  bg: '#fff',
  fg: 'rgb(37, 37, 37)'
})

const ActionLogsPanel = ({ action, repopsitoryId, hasRunStatus }) => {
  const [fetchInterval, setFetchInterval] = useState(2000)
  const containerRef = React.useRef()
  const [follow, setFollow] = React.useState(true)
  const [isFullscreen, setFullscreen] = React.useState(false)
  const [wrapLines, setWrapLines] = useState(false)

  React.useLayoutEffect(() => {
    if (containerRef.current && follow) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight
    }
  })

  const { queryId, query, config } = {
    queryId: ['fetchActionLogs', action.action_id],
    query: fetchActionLogs,
    config: {
      refetchInterval: !action.endedAt ? fetchInterval : undefined,
      retry: (_, error) => {
        if (error && error.status === 404) {
          setFetchInterval(false)
          return false
        }
        return true
      }
    }
  }

  const { data, error, isLoading } = useQuery(queryId, query, config)

  if ((error && error.status === 404) || !data) {
    return (
      <p>Something is not right. We don't seem to have this log anymore.</p>
    )
  }

  if (error) {
    return <p>Error while fetching logs: {JSON.stringify(error)}</p>
  }

  const lines = !isLoading && data.length === 0 ? ['No logs yet...'] : data
  if (!isLoading) {
    return (
      <div className='relative'>
        <pre
          className={cx('overflow-auto outline-none bg-white', {
            'w-screen': isFullscreen,
            'whitespace-normal': wrapLines
          })}
          re={containerRef}
          style={{
            height: isFullscreen ? '100vh' : '50vh',
            maxHeight: isFullscreen ? '100vh' : '480px'
          }}
        >
          <div className='p-4'>
            {lines.map((line, index) => {
              return (
                <div
                  key={index}
                  // We are trusting ansi-to-html's escapeXML flag to work securely
                  dangerouslySetInnerHTML={{ __html: ansi.toHtml(line) }}
                />
              )
            })}
          </div>

          {isLoading && (
            <div className={cx('p-4', { 'pt-0': lines.length > 0 })}>
              <MerryGoRound />
            </div>
          )}
        </pre>
        <div className='absolute top-1 right-1 rounded-full md:top-2 md:right-2 bg-white'>
          {!isFullscreen && (
            <IconButton
              icon={<FullScreenIcon />}
              onClick={() => setFullscreen(true)}
            />
          )}
          {isFullscreen && (
            <IconButton
              icon={<CloseIcon />}
              onClick={() => setFullscreen(false)}
            />
          )}
        </div>
        <div className='absolute bottom-1 right-1 md:bottom-2 md:right-2 bg-white'>
          <Checkbox
            checked={wrapLines}
            label={'Wrap Lines'}
            onChange={() => setWrapLines(!wrapLines)}
          />
        </div>
      </div>
    )
  }
}

export default ActionLogsPanel
