import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import ActionDetailsPage from './ActionDetailsPage'
import { QueryClient, QueryClientProvider } from 'react-query'

import React, { Fragment } from 'react'
import ActionsPage from './ActionsPage'

export function toTitleCase(str) {
  if (str === null) {
    return
  }
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

const App = () => {
  const basename = window.location.pathname.startsWith('/node')
    ? '/node'
    : undefined
  const queryClient = new QueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <Router basename={basename}>
        <Routes>
          <Route
            exact
            path='/:repoName/actions/:actionId'
            element={<ActionDetailsPage />}
          ></Route>
          <Route path='/:repoName/actions/' element={<ActionsPage />}></Route>
        </Routes>
      </Router>
    </QueryClientProvider>
  )
}

export default App
