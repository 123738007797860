import { securedFetch } from '@local/jwt-manager'

export const checkResponse = (response) => {
  if (response.redirected && !response.ok) {
    return fetch(response.url).then(checkResponse)
  }

  if (!response.ok) {
    response.message = `Error while executing request: ${response.url}. Status: ${response.status}`
    return Promise.reject(response)
  }
  return Promise.resolve(response)
}

const toJson = (response) => {
  return checkResponse(response).then((r) => r.json())
}

const toText = (response) => {
  return checkResponse(response).then((r) => r.text())
}

export const downloadBinary = (url) =>
  securedFetch(url)
    .then(checkResponse)
    .then(
      (response) =>
        new Promise((resolve, reject) => {
          response
            .blob()
            .then((blob) => resolve(blob, response.headers.get('content-type')))
            .catch(reject)
        })
    )

export const fetchActionDetails = (queryFn) => {
  const params = queryFn.queryKey[1]
  return securedFetch(`/v1/actions/${params.repoName}/${params.actionId}`).then(
    toJson
  )
}

export const fetchActionLogs = (queryFn) => {
  const params = queryFn.queryKey[1]
  console.log(params)
  return securedFetch(`/v1/actions/${encodeURIComponent(params)}/logs`).then(
    toJson
  )
}

export const fetchActions = (queryFn) => {
  const params = queryFn.queryKey[1]
  // TODO: Add repoName to query to limit results
  let url = `/v1/actions/${params.repoName}?page=${params.currentPage}&pageSize=10`
  return securedFetch(url).then(toJson)
}
