import React from 'react'
import {
  CheckCircleSelectedFillIcon,
  CancelIcon,
  AutorenewIcon
} from '@toasttab/buffet-pui-icons'
import { toTitleCase } from '../pages/App'

export const getActionStatusIcon = (status) => {
  if (status === `Succeeded`)
    return (
      <CheckCircleSelectedFillIcon
        style={{ color: 'green' }}
        accessibility='decorative'
      />
    )
  if (status === `Executing` || status === `Queued`)
    return (
      <AutorenewIcon
        style={{ color: 'blue' }}
        className='animate-spin'
        accessibility='decorative'
      />
    )
  return <CancelIcon style={{ color: 'red' }} accessibility='decorative' />
}

const ActionStatus = ({ status }) => {
  status = toTitleCase(status)
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <p style={{ flex: 1 }}>{status}</p>
      <div style={{ marginLeft: '2px' }}>{getActionStatusIcon(status)}</div>
    </div>
  )
}

export default ActionStatus
