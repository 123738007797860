import { LocalJWTManager } from './LocalJWTManager'

const jwtManager = new LocalJWTManager()

export async function securedFetch(url: RequestInfo, init = {}) {
  init = addAuthHeaders(init)
  const response = await fetch(url, init)
  return await checkAuth(response, url, init)
}

function addAuthHeaders(init: RequestInit) {
  const jwtHeader = jwtManager.encodedToken
    ? { Authorization: `bearer ${jwtManager.encodedToken}` }
    : null
  if (jwtHeader) {
    init.headers = { ...(init.headers || {}), ...jwtHeader }
  }
  return init
}

async function checkAuth(response: Response, url: RequestInfo, init: RequestInit) {
  if (response.status === 401) {
    await jwtManager.refresh()
    init = addAuthHeaders(init)
    return await fetch(url, init)
  }
  return response
}
