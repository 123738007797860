import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import {
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels
} from '@toasttab/buffet-pui-tabs'
import { fetchActionDetails } from '../queries'
import ActionLogsPanel from '../components/ActionLogsPanel'
import { toTitleCase } from './App'
import ActionStatus from '../components/ActionStatus'

const isFinished = (action) => action !== null && action.finishedAt !== null

const convertUTCToDate = (utc) => {
  if (!utc) {
    return ''
  }
  let date = new Date(utc)
  return date.toLocaleString()
}

const getTimeLeftToLive = (ttl, startedTime) => {
  let ttlHours = Number(ttl.split('h')[0])
  // Convert ttl to datetime
  let timeToDie = new Date(startedTime)
  // Add hours to started time equal to ttl
  let millisecondsToLive = ttlHours * 60 * 60 * 1000
  timeToDie.setTime(timeToDie.getTime() + millisecondsToLive)

  // Get the time left to live
  let currentTime = Date.now()
  if (timeToDie < currentTime) {
    // We're already dead
    return 'Should already be destroyed'
  }
  let millisecondsLeftToLife = timeToDie - currentTime

  // Turn this into something readable
  let hoursLeftToLive = Math.floor(millisecondsLeftToLife / 1000 / 60 / 60)
  let daysLeftToLive = Math.floor(hoursLeftToLive / 24)
  let remainingHours = hoursLeftToLive % 24
  return `${daysLeftToLive} day${
    daysLeftToLive === 1 ? '' : 's'
  } ${remainingHours} hour${remainingHours === 1 ? '' : 's'}`
}

const ActionLabel = ({ children }) => (
  <label className='font-semibold'>{children}</label>
)

const ActionField = ({ children }) => (
  <p className='flex flex-row type-default' style={{ width: '25%' }}>
    {children}
  </p>
)

const OneLineActionField = ({ children }) => (
  <p className='flex flex-row type-default'>{children}</p>
)

const ActionDetailsPage = () => {
  const { actionId, repoName } = useParams()
  const [keepFetching, setKeepFetching] = useState(true)

  const {
    data: action,
    error,
    isLoading
  } = useQuery(
    [
      'fetchActionDetails',
      {
        actionId,
        repoName
      }
    ],
    fetchActionDetails,
    { refetchInterval: keepFetching ? 3000 : false }
  )

  if (isLoading) {
    return (
      <div className='pin-center'>
        <MerryGoRound />
      </div>
    )
  }

  if (error) {
    return (
      <p className='mx-4 md:mx-0'>An error happened: {JSON.stringify(error)}</p>
    )
  }

  if (keepFetching && isFinished(action)) {
    setKeepFetching(false)
  }

  // We'll need to replace the status field with a real field once the statuses are finalized
  // We also need to build the link to the parent build. Just trying to keep this simple for now.
  return (
    <>
      <div className='px-4 md:px-0'>
        <div className='flex flex-row gap-2 justify-stretch'>
          <div className='flex-grow my-2 space-y-2'>
            <span className='flex flex-row'>
              <ActionField>
                <ActionLabel>Service Name:</ActionLabel>
                <div>{action.serviceName}</div>
              </ActionField>
              <ActionField>
                <ActionLabel>Type:</ActionLabel>
                <div>{toTitleCase(action.actionType)}</div>
              </ActionField>
            </span>
            <span className='flex flex-row'>
              <ActionField>
                <ActionLabel>Status:</ActionLabel>
                <div>
                  <ActionStatus status={action.status} />
                </div>
              </ActionField>
              <ActionField>
                <ActionLabel>Revision:</ActionLabel>
                <div>{action.revision}</div>
              </ActionField>
            </span>
            <span className='flex flex-row'>
              <ActionField>
                <ActionLabel>Started At:</ActionLabel>
                <div>{convertUTCToDate(action.startedAt)}</div>
              </ActionField>
              <ActionField>
                <ActionLabel>Deployment Number:</ActionLabel>
                <div>{action.deploymentNumber}</div>
              </ActionField>
            </span>
            <span className='flex flex-row'>
              <ActionField>
                <ActionLabel>Ended At:</ActionLabel>
                <div>{convertUTCToDate(action.endedAt)}</div>
              </ActionField>
              <ActionField>
                <ActionLabel>Triggered By:</ActionLabel>
                <div>{action.userEmail}</div>
              </ActionField>
            </span>
            <span className='flex flex-row'>
              <ActionField>
                <ActionLabel>Environment:</ActionLabel>
                <div>{toTitleCase(action.toastEnvironment)}</div>
              </ActionField>
              <ActionField>
                <Link to={`/${action.repositoryName}/builds/${action.buildId}`}>
                  Parent Build
                </Link>
              </ActionField>
            </span>
            {!!action.ttl ? (
              <span className='flex flex-row'>
                <OneLineActionField>
                  <ActionLabel>Time Left to Live:</ActionLabel>
                  <div>{getTimeLeftToLive(action.ttl, action.startedAt)}</div>
                </OneLineActionField>
              </span>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      <Tabs className='w-full my-6'>
        <TabList className='w-full px-4 md:px-0'>
          <Tab>Logs</Tab>
        </TabList>
        <TabPanels className='py-4 md:py-6'>
          <TabPanel>
            <ActionLogsPanel
              action={action}
              repositoryId={action.repositoryId}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  )
}

export default ActionDetailsPage
