import React from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import ActionsList from '../components/ActionsList'
const ActionsPage = () => {
  const { repoName } = useParams()
  const [searchParams] = useSearchParams()
  const [refetch, setRefetchActions] = React.useState(false)
  const [page, setPage] = React.useState(Number(searchParams.get('page')))

  const setPageNumber = (newPage) => {
    setPage(newPage)
    // Set the search params without using the React dom
    const url = new URL(window.location)
    url.searchParams.set('page', newPage)
    window.history.pushState({}, '', url)
  }
  return (
    <>
      <ActionsList
        repoName={repoName}
        refetchActions={refetch}
        setRefetchActions={setRefetchActions}
        currentPage={page === undefined ? 1 : page}
        onSetPage={setPageNumber}
      />
    </>
  )
}

export default ActionsPage
